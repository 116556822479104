.tiles {
  .tile-inner {
    background-color: white;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);

    margin: 15px 0 15px 7.5px;

    width: calc(100% - 15px);
    height: 138px;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    transition: background-color 500ms;

    .tile-name {
      color: #2b2b2b;
      font-weight: 600;
      margin: 10px 0 0;
    }

    &:hover {
      background-color: #00a6de;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);

      .tile-image svg path, .tile-image svg ellipse {
        stroke: white;

        &:not(.no-fill) {
          fill: white;
        }

        &.transparent {
          fill: #00a6de;
          stroke: #00a6de;
        }
      }

      .tile-name {
        color: white;
      }
    }
  }

  .tile-green .tile-name {
    color: #00c292;
  }

  .tile-light .tile-name {
    font-weight: 500;
  }

  .tile-active {
    .tile-inner {
      background-color: #00c292;

      &:hover {
        background-color: #009e76;
      }
    }

    .tile-image svg path {
      stroke: white;
      fill: white;
    }

    .tile-name {
      color: white;
    }
  }
}
