@import 'tiles.scss';

html,
body {
  position:relative;
  overflow-x: hidden;
}

.hm-shape-outer .hm-shape-inner {
  top: -98px;
}

.p-0{
  padding: 0!important;
}

.m-b-0{
  margin-bottom: 0!important;
}

.m-t-0{
  margin-top: 0!important;
}

.m-t-15{
  margin-top: 15px!important;
}

.navbar-default .navbar-brand {
  h1 {
    display: inline;

    img {
      max-height: 100%;
      width: auto;
    }
  }
}

// colors from presentation/less/main/structure/colors.less
$yellow: #f9b81e;
$green:  #add747;
// breakpoints from presentation/less/bootstrap/variables.less
$screen-xs:   480px;
$screen-sm:   768px;
$screen-md:   992px;
$screen-lg:  1200px;

.carousel {
  .carousel-caption h2 {
    text-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px;
    &:before{
      border-width: 2px 0 0 2px;
    }
    &:after{
      border-width: 2px 2px 0 0;
    }
  }

  .carousel-caption {
    @media (min-width: $screen-sm) {
      top: 60%;
    }

    @media (min-width: 1600px) {
      top: 55%;
    }

    h2 {
      text-transform: none;
    }

    .lead {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      color: $yellow;

      @media (min-width: $screen-sm) {
        font-size: 30px;
      }
      @media (min-width: $screen-md) {
        font-size: 50px;
      }
      @media (min-width: $screen-lg) {
        font-size: 70px;
      }
    }
  }

  .carousel-inner .item img {
    @media(min-width: $screen-md) {
      min-height: 550px;
    }
  }
}

$blueBg: #03a7e0;
$bluePrimary: #009cff;

.header-top-bar {
  background-color: $blueBg;
  position: relative;
  z-index: 10;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  a {
    font-weight: bold;
  }

  &:before {
    background: transparent !important;
  }
}

#header {
  .navbar-nav:before {
    box-shadow: black -3px 7px 10px -10px;
    right: calc(-1000% + 783px);

    @media only screen and (max-width: 1200px) and (min-width: 1024px) {
      right: calc(-1000% + 605px);
    }
    @media only screen and (max-width: 1024px) and (min-width: 992px) {
      right: -45px;
      width: 649px;
    }
  }
  .mobile-menu a i{
    color: $bluePrimary;
  }
}

.services .items:hover::after{
  display: none!important;
}

.services{
  .items {
    .shape i{
      font-size: 80px;
    }
  }
}

.services {
  .panel {
    border: 1px solid transparent;
    box-shadow: none;
  }

  .panel-heading {
    text-align: center;
    background-color: transparent;
    border-bottom: 1px solid transparent;

    .panel-title {
      margin-top: 15px;
    }

    .panel-img {
      max-height: 80px;
    }
  }

  .panel-warning > .panel-heading {
    color: $yellow;
  }

  .panel-success > .panel-heading {
    color: $green;
  }

  .panel-footer {
    background-color: transparent;
    border-top: 1px solid transparent;
    text-align: center;

    .btn {
      display: inline-block;
      width: 100%;
      background-color: transparent;
      border-width: 2px;
      font-weight: bold;
      color: $bluePrimary;
      padding: 5px 10px;
      font-size: 12px;

      &:hover, &:active {
        color: #fff;
        background-color: darken($bluePrimary, 12%);
        border-color: darken($bluePrimary, 14%);
      }
    }
  }
}

footer .about img {
  max-width: 80%;
}

footer .footer-section {
  background-color: #1c1c1c;
  padding: 115px 0 45px;
}

.mask{
  border-left: 70vw solid #fff;
  border-right: 50vw solid #fff;
}

.testimonials{
  background: url('../images/slider/bg-dark.jpg');
  background-size: cover;
  .carousel figure {
    .designation{
      color: rgba(255, 255, 255, 0.7);
    }
    .client-name {
      color: white;
    }
    img {
      border: 3px solid #ffffff;
    }
  }
  p{
    color: rgb(224, 241, 247);
  }
}

.hm-company-info {
  position: relative;
  z-index: 10;

  > .container {
    position: relative;
    z-index: 10000;
  }
}

.hm-portfolio,
.hm-company-info {
  padding: 35px 0 45px 0;

  background: url("../images/bg5.jpg");
  background-size: 80%;
  position: relative;
  overflow: visible;
  &:before{
    content: " ";
    display: block;
    top: 0px;
    position: absolute;
    z-index: 1000;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 100vw 60px 0;
    border-color: transparent white transparent transparent;
  }
  &:after{
    content: " ";
    display: block;
    bottom: 0;
    position: absolute;
    z-index: 1000;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 60px 0 0 100vw;
    border-color: transparent transparent transparent white;
  }
}

.hm-company-info1 {
  &:before{
    content: " ";
    display: block;
    top: 0px;
    position: absolute;
    z-index: 1000;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 60px 100vw 0 0;
    border-color: white transparent transparent transparent;
  }
  &:after{
    content: " ";
    display: block;
    bottom: 0;
    position: absolute;
    z-index: 1000;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 60px 100vw;
    border-color: transparent transparent white transparent;
  }
}

.hm-company-info.hm-company-info1 {
  background-image: none;
  padding: 30px 0 0 0;
}

.hm-portfolio .portfolio-list {
  margin: 30px 0 0;
}

.hm-company-info {
  .section-image {
    display: inline-block;
    margin: 120px auto 0 auto;
  }
}

body .hm-company-info .hm-company-detail p {
  padding: 0;

  &:first-of-type {
    padding: 41px 0 0;
  }
}

.hm-company-info .hm-company-detail ul li {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 30px;
}

.team, .pricing-table, section, .partners {
  padding: 85px 0;
}

.partners{
  border-top: 1px #e5e5e5 solid;
}

.pricing-table .basic .pricing-detail, .pricing-table .silver .pricing-detail, .pricing-table .platinum .pricing-detail, .pricing-table .custom .pricing-detail{
  background: white;
}

.pricing-table{
  background: white;
}

.pricing-table .btn {
  font-weight: bold;
}


.pricing-table {
  .basic .btn{
    border: 1px #f2b375 solid;
  }
  .silver .btn{
    border: 1px #babec5 solid;
  }
  .custom .btn{
    border: 1px #50b6de solid;
  }
  .platinum .btn{
    border: 1px #ffcc27 solid;
  }
}

.services{
  padding-bottom: 0;
}

.wrap-image {
  margin: 20px;
}

#page-wrapper {
  .bg-title {
    z-index: 1050;

    * {
      z-index: 1050;
    }

    margin-bottom: 0;
    background-color: white;

    .go-back {
      line-height: 60px;
      min-height: 60px;

      a {
        color: #00a6de;

        &:hover {
          color: #11b7ef;
        }
      }
    }

    .tanix-logo-wrapper {
      display: none;

      z-index: 1060;

      top: 0;
      left: 50%;
      transform: translateX(-50%);

      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: #00a6de;

      box-sizing: content-box;
      padding: 10px;

      @media(min-width: 768px) {
        height: 19vw;
        width: 19vw;

        display: flex;
      }

      min-height: 180px;
      min-width: 132px;

      max-height: 253px;
      max-width: 254px;

      position: absolute;

      justify-content: center;
      align-items: center;

      img {
        height: 180px;
      }
    }
  }

  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    min-height: calc(100% - 85px);
    margin: 85px 0 0 0;

    footer {
      color: #58666e;
      padding: 20px;
      margin-bottom: 0;

      a:hover {
        color: #11b7ef;
      }
    }
  }

  #content {
    .description {
      margin: 1em 0 2em 0;

      color: #686868;
      font-size: 1.3em;
    }

    padding: 250px 0 100px;
    background: url('../images/bg5.jpg');
  }
}

.text-gray {
  color: #8d9ea7;
}

.text-blue {
  color: #00adef !important;
}

.modal-header .close {
  width: auto;
}

.grid-view {
  margin-bottom: 40px;

  tbody > tr > td {
    vertical-align: middle;

    .ti {
      font-weight: bold;
    }
  }
}

#section-service {
  .services-box,
  .services-box-outer {
    display: flex;
    align-items: stretch;
  }

  .services-row-inner {
    @media (min-width: $screen-sm) {
      display: flex;
      align-items: stretch;
    }
  }

  .services-row {
    text-align: center;

    @media (min-width: $screen-md) {
      display: flex;
      align-items: stretch;
    }
  }

  .services-box-outer {
    @media (min-width: $screen-sm) and (max-width: $screen-md + 1) {
      display: inline-block;
      float: none;
    }
  }

  .services-box,
  .services-box-outer {
    text-align: left;

    .panel {
      position: relative;
      padding-bottom: 53px;
    }

    .panel-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

@media (max-width: $screen-md + 1) {
  .hm-company-info {

    &.hm-company-info1 {
      .section-image {
        margin-top: 15px;
      }
    }

    &:not(.hm-company-info1) {
      .section-image {
        margin-top: 45px;
      }
      .container > .row {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}

.text.text-support {
  &.text-danger {
    color: #F33;
  }

  &.text-success {
    color: #00c292;
  }
}

.table .action-column{
  padding: 0px 8px 15px;
  position: relative;
  .form-control{
    padding: 0;

    z-index: 2;
    position: relative;

    height: 25px;
    margin-left: 5px;
  }
  .fa
  {
    position: absolute;
    bottom: 6px;
    left: -12px;
    z-index: 1;
    width: 25px;
    height: 30px;
    padding-top: 7px;
    border-radius: 5px;
    padding-left: 5px;
    vertical-align: middle;
  }
}

.left-gray-border {
  border-left: 1px solid #e6e6e6;
}

li .btn-group .btn.language-picker {
  border-radius: 4px;
  background: none;

  color: black;
  width: auto;
  padding: 24px 0;
  box-shadow: none;
}

#reference {
  .top-section p {
    max-width: 500px;
    text-align: center;
    margin: 0 auto;
  }

  .white-box {
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.1);
    height: 200px;
    line-height: 180px;
    text-align: center;
    margin: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 180px;
      padding: 20px;

    }

    img {

      &.bluematic-logo {
        width: 220px;

        padding-left: 23px;
        padding-right: 17px;
      }

      &.lora-logo {
        max-height: 140px;
        max-width: 202px;
      }


      &.lora-wan-logo {
        padding: 35px;
      }

      &.tesla-logo {
        padding: 60px;
      }

      &.cvut-logo, &.ctu-logo {
        padding: 40px;
      }

      &.xignal-logo {
        padding: 45px;
      }

      &.potravinarskeporadenstvi-logo {
        width: 170px;
        height: 170px;
      }
    }
  }

  .logos {
    padding-top: 0;
  }
}

#footer {
  overflow-x: hidden;
}
